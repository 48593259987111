import styled, { css } from 'styled-components';
import Flex from '../Flex';

export const Container = styled(Flex)`
  ${({ theme: { color }, isMobile }) => css`
    padding: 24px;
    gap: 24px;
    background-color: ${color.lightGreen};
    border-radius: 16px;
    word-break: break-word;

    ${isMobile && css`
      padding: 16px;
      gap: 16px;
      border-radius: 12px;
      margin-right: 16px;
      flex: 0;
    `}
  `}
`;

export const Image = styled.img`
  ${({ isMobile }) => css`
    height: 220px;
    width: 220px;
    border-radius: 16px;
    
    ${isMobile && css`
      height: 138px;
      width: 138px;
    `}
  `}
`;

export const TextContainer = styled(Flex)`
  ${({ isMobile }) => css`
    width: 204px;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    ${isMobile && css`    
      justify-content: flex-start;
    `}
  `}
`;

