import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import {
  Flex, Typography, Icon, InputField, ButtonWithLoader, LanguageSwitcher,
} from '@components';
import { useWindowSize } from '@hooks';
import { routes } from '@utils';
import { NUMBER_REGEX, STORE_NAMES } from '@constants';
import { verifyEmail, verifyResetPassword } from '@store';
import {
  clearToken, setToken, getSubscriptionId, clearSubscriptionId,
} from '@utils/storage';
import { showErrorToast } from '@utils/helpers';
import { ReactComponent as ArrowBack } from '@assets/ArrowBack.svg';
import styled from 'styled-components';
import { clearGtmDataLayer, sha256 } from 'src/utils/helpers';
import { PreviewImage } from './components';

const VerifyEmailScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isMobile, isDesktop] = useWindowSize();

  const [subscriptionId] = useState(getSubscriptionId());

  const { authorization_info: { email }, isPending } = useSelector(store => store[STORE_NAMES.AUTH]);
  const [verifyCode, setVerifyCode] = useState('');

  const handleChangeVerifyCode = e => {
    const { value } = e.target;

    if (NUMBER_REGEX.test(value) || value.length > 6) {
      return;
    }

    setVerifyCode(value);
  };

  const removeSubscriptionId = () => {
    if (subscriptionId) {
      clearSubscriptionId();
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const trackGTMSignup = async customerTokenCreds => {
    const { signupMethod } = location.state;

    clearGtmDataLayer();
    window.dataLayer.push({
      event: 'sign_up',
      eventProps: {
        method: signupMethod,
      },
      meta: {
        email: await sha256(customerTokenCreds.email),
      },
      userProps: {
        user_id: customerTokenCreds.id,
      },
    });
  };

  const handleLogin = async token => {
    clearToken();
    removeSubscriptionId();
    setToken(token);

    const decodedCredential = jwt_decode(token);

    // await trackCompleteRegistration();
    await trackGTMSignup(decodedCredential);

    const historyPath = subscriptionId
      ? `${routes.subscriptions}/${subscriptionId}`
      : routes.subscriptions;

    history.push(historyPath);
  };

  const handleSubmit = async () => {
    const { isVerifyEmail } = location.state;

    const result = await dispatch(isVerifyEmail
      ? verifyEmail({
        email,
        verificationCode: verifyCode,
        language: 'English',
      })
      : verifyResetPassword({
        email,
        verificationCode: verifyCode,
      }));

    if (result.error) {
      showErrorToast(result.payload.data.error.message);
    } else if (isVerifyEmail) {
      await handleLogin(result.payload.token);
    } else {
      history.push(routes.changePassword);
    }
  };

  return (
    <Flex minHeight="100vh">
      {isDesktop && <PreviewImage />}
      <Flex justifyCenter>
        <Flex column maxWidth={isMobile ? '80%' : '55%'} marginTop="140px" position="relative">
          {isDesktop ? (
            <>
              <Flex
                grow="0"
                position="absolute"
                left="0"
                top="135px"
                cursor="pointer"
                onClick={handleGoBack}
              >
                <Icon SVG={ArrowBack} />
              </Flex>
              <Flex justifyCenter marginBottom="80px" grow="0">
                <LanguageSwitcher />
              </Flex>
            </>

          ) : (
            <Flex
              grow="0"
              marginBottom="xl7"
              cursor="pointer"
              onClick={handleGoBack}
            >
              <Icon SVG={ArrowBack} />
            </Flex>
          )}
          <Typography
            color="dark"
            size="xl4"
            weight="bold"
            textAlign="center"
            marginBottom="xl"
          >
            {t('VerifyEmailScreen.Title')}
          </Typography>
          <Typography
            textAlign="center"
            color="darkGrey"
            marginBottom="xl7"
          >
            {t('VerifyEmailScreen.Subtitle')}
            <Span>{email}</Span>
          </Typography>
          <InputField
            label={t('VerifyEmailScreen.Code')}
            value={verifyCode}
            onChange={handleChangeVerifyCode}
          />
          <Flex marginTop="xl4">
            <ButtonWithLoader
              onClick={handleSubmit}
              text={t('VerifyEmailScreen.Verify')}
              isLoading={isPending}
            />
          </Flex>
          {!isDesktop && (
            <Flex justifyCenter marginTop="xl6">
              <LanguageSwitcher />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const Span = styled.span`
  font-size: 16px;
  color: #6A7587;
  font-weight: 700;
`;

export default VerifyEmailScreen;
