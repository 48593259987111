import React, { useEffect, useRef, useState } from 'react';
import { Flex, Typography } from '@components';
import { useWindowSize } from '@hooks';
import { createStripeSubscription } from '@store';
import {
  useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement,
} from '@stripe/react-stripe-js';
import { routes } from '@utils';
import {
  bool, func, number, shape, string,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PAYMENT_SUCCEEDED, SUBSCRIPTION_DOWNGRADED, TRACKING_PRODUCT_TYPES } from 'src/constants/common';
import {
  clearGtmDataLayer, sha256, showErrorToast, showSuccessToast,
} from 'src/utils/helpers';
import styled, { css } from 'styled-components';
import { STORE_NAMES } from '@constants';
import {
  resetUserPaymentFlowState,
  setDiscount,
  setIsBuyButtonDisabled,
  setIsPaymentProcessing,
  setPromocode,
  setPromotionName,
} from 'src/store/products/duck';
import BuySubscriptionBlock from '../../BuySubscriptionBlock/BuySubscriptionBlock';
import { CARD_ELEMENT_OPTIONS } from './layoutProps';
import ConfirmationModal from '../../../../../../components/ConfirmationModal/ConfirmationModal';

const PayWithCardTab = ({
  subscription, trackInitiateCheckout, onSubmit, isFormDisabled, emailValue,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [, isDesktop] = useWindowSize();
  const stripe = useStripe();
  const elements = useElements();
  const customerStore = useSelector(store => store[STORE_NAMES.CUSTOMER]);

  const currentCustomerSubscription = customerStore.customer?.currentSubscription;

  const {
    availableProducts: { has_active_subscription },
    userPaymentFlow: {
      discount,
      freeDays,
      promocode,
      promotionName,
    },
    userPurchases: { subscription: purchasedSubscription },
  } = useSelector(store => store[STORE_NAMES.PRODUCTS]);

  const { authorization_info } = useSelector(store => store[STORE_NAMES.AUTH]);

  const isAddPaymentInfoTracked = useRef(false);

  useEffect(() => {
    dispatch(resetUserPaymentFlowState());
  }, []);

  const trackGTMAddPaymentInfo = async ({ sub, promotionProps = {} }) => {
    clearGtmDataLayer();

    window.dataLayer.push({
      event: 'add_payment_info',
      eventProps: {
        currency: 'NOK',
        value: typeof promotionProps.discount === 'number'
          ? (sub.price - promotionProps.discount) / 100
          : sub.price / 100,
        coupon: promotionProps.coupon,
        payment_type: 'card',
        items: [{
          item_id: sub.id,
          item_name: sub.title,
          item_category: TRACKING_PRODUCT_TYPES.subscription,
        }],
      },
      meta: {
        email: await sha256(authorization_info.email),
        eventProps: {
          content_category: 'fitness',
          content_ids: [sub.id],
          contents: [{
            id: sub.id,
            quantity: 1,
          }],
          value: typeof promotionProps.discount === 'number'
            ? (sub.price - promotionProps.discount) / 100
            : sub.price / 100,
          currency: 'NOK',
        },
      },
      userProps: {
        user_id: authorization_info.id,
        subscription_id: sub.id,
      },
    });
  };

  const handleDisablePaymentButton = async element => {
    if (!isAddPaymentInfoTracked.current) {
      await trackGTMAddPaymentInfo({
        sub: subscription,
        promotionProps: {
          discount,
          coupon: promocode,
          promotionName,
        },
      });
      isAddPaymentInfoTracked.current = true;
    }

    dispatch(setIsBuyButtonDisabled(!element.complete));
  };

  const finishSubmit = () => {
    dispatch(setPromotionName(''));
    dispatch(setPromocode(''));
    dispatch(setDiscount(0));

    elements.getElement('cardNumber')?.clear();
    elements.getElement('cardExpiry')?.clear();
    elements.getElement('cardCvc')?.clear();

    dispatch(setIsPaymentProcessing(false));
    setIsConfirmationModalOpen(false);
  };

  const handlePaymentSubmit = async () => {
    try {
      if (!stripe || !elements) {
        return;
      }
      setIsConfirmationModalOpen(false);

      dispatch(setIsPaymentProcessing(true));

      trackInitiateCheckout({
        sub: subscription,
        promotionProps: {
          discount,
          coupon: promocode,
          promotionName,
        },
      });

      let paymentMethodId = null;
      if (discount === subscription.price || freeDays) {
        const res = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement('cardNumber'),
        });

        if (res.error) {
          showErrorToast(res.error.message);
        }

        paymentMethodId = res.paymentMethod.id;
      }

      const { customer } = await onSubmit();

      if (!customer) {
        finishSubmit();
        return;
      }

      const result = await dispatch(createStripeSubscription({
        query: { isInitialBuy: !has_active_subscription },
        data: {
          subscriptionId: subscription.id,
          promocode,
          paymentMethodId,
          customerId: customer.id,
        },
      }));

      if (result.error) {
        showErrorToast(result.payload.data.error.message);
      } else {
        if (result.payload.status === SUBSCRIPTION_DOWNGRADED) {
          showSuccessToast(t('Toast.Downgraded'));
          finishSubmit();
          return;
        }

        if (!result.payload.client_secret) {
          history.push(`${routes.success}?subscriptionId=${subscription.id}`);
          return;
        }

        const response = await stripe.confirmCardPayment(result.payload.client_secret, {
          payment_method: {
            card: elements.getElement('cardNumber'),
          },
          setup_future_usage: 'off_session',
        });

        if (response.error) {
          showErrorToast(response.error.message);
        } else if (response.paymentIntent.status === PAYMENT_SUCCEEDED) {
          history.push(`${routes.success}?subscriptionId=${subscription.id}`, {
            paymentIntentId: response.paymentIntent.id,
            promocode,
            discount,
            promotionName,
          });
        }
      }

      finishSubmit();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      finishSubmit();
    }
  };

  const cardElementOptions = {
    ...CARD_ELEMENT_OPTIONS,
    disabled: isFormDisabled,
  };

  const handleSubscribeButtonClick = async event => {
    event.preventDefault();
    const currentDuration = Number(currentCustomerSubscription?.duration || purchasedSubscription?.duration);

    const hasDifferentDuration = currentDuration && currentDuration !== subscription?.duration;

    if (!hasDifferentDuration) {
      await handlePaymentSubmit();
      return;
    }

    setIsConfirmationModalOpen(true);
  };

  const handleConfirmationModalClose = () => setIsConfirmationModalOpen(false);

  return (
    <Wrapper
      column
      justifyStart
      width={isDesktop ? '50%' : '100%'}
      rowGap={isDesktop ? '32px' : '20px'}
      marginTop="32px"
      display={!isDesktop ? 'none' : 'flex'}
    >
      {purchasedSubscription
      && purchasedSubscription.price >= subscription.price
      && purchasedSubscription.duration !== subscription.duration
      && (
        <Typography color="grey" padding="sm" paddingBottom="0">
          {t('GetSubscription.Warning')}
        </Typography>
      )}
      <Flex column grow="0" gap="16px">
        <CardNumberElement onChange={handleDisablePaymentButton} options={cardElementOptions} />
        <Flex gap="16px">
          <CardExpiryElement onChange={handleDisablePaymentButton} options={cardElementOptions} />
          <CardCvcElement onChange={handleDisablePaymentButton} options={cardElementOptions} />
        </Flex>
      </Flex>
      <ConfirmationModal
        title={t('DowngradeConfirmation.Title')}
        subtitle={t('DowngradeConfirmation.Subtitle')}
        isOpen={isConfirmationModalOpen}
        onClose={handleConfirmationModalClose}
        onConfirm={handlePaymentSubmit}
        confirmButtonText={t('General.Confirm')}
        cancelButtonText={t('General.Cancel')}
        cancelButtonProps={{
          color: 'blueDark',
        }}
        confirmButtonProps={{
          color: 'redDark',
        }}
      />
      <BuySubscriptionBlock
        handleSubmit={handleSubscribeButtonClick}
        subscriptionId={subscription.id}
        subscriptionPrice={subscription.price}
        isStripeLoaded={Boolean(stripe)}
        isProduct={false}
        isFormDisabled={isFormDisabled}
        emailValue={emailValue}
      />
    </Wrapper>
  );
};

PayWithCardTab.propTypes = {
  subscription: shape({
    id: number.isRequired,
    price: number.isRequired,
    price_after_first_payment: number.isRequired,
    duration: number.isRequired,
    title: string.isRequired,
    title_no: string.isRequired,
    has_chat: bool.isRequired,
    has_diet_plan: bool.isRequired,
    has_training_program: bool.isRequired,
    has_own_training_program: bool.isRequired,
    has_coach: bool.isRequired,
    has_coaching: bool.isRequired,
    has_booklets: bool.isRequired,
    has_recipes_db: bool.isRequired,
  }),
  trackInitiateCheckout: func.isRequired,
  onSubmit: func.isRequired,
  isFormDisabled: bool.isRequired,
  emailValue: string.isRequired,
};

PayWithCardTab.defaultProps = {
  subscription: {},
};

const Wrapper = styled(Flex)`
  ${({ theme: { color, spacing, border } }) => css`
  .StripeElement {
    width: 100%;
    padding: ${spacing.sm};
    border: 1.5px solid ${color.lightGrey};
    border-radius: ${border.radius.sm};
  }
  `}
`;

export default PayWithCardTab;
