export const styles = {
  container: {
    paddingBottom: 'xl4',
    width: '100%',
    marginTop: '16px',
    gap: '32px',
  },
  paymentSubtitle: {
    color: 'greyApple',
    size: 'md',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  firstPaymentTitle: {
    weight: 'bold',
    size: 'xl5',
    color: 'green',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  firstPaymentContainer: {
    column: true,
    width: '60%',
    bgColor: 'lightGreen',
    padding: '16px 24px',
    borderRadius: '16px',
    itemsCenter: true,
    justifyCenter: true,
  },
  nextPaymentTitle: {
    size: 'xl4',
    color: 'darkSecondary',
  },
  nextPaymentContainer: {
    grow: '0',
    column: true,
    padding: '16px 24px',
    itemsCenter: true,
    justifyCenter: true,
  },
};
