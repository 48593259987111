import { Flex, Typography } from '@components';
import React from 'react';
import { number } from 'prop-types';
import { formatSubscriptionPrice } from '@utils/helpers';
import { useTranslation } from 'react-i18next';
import { styles } from './styles';

const PaymentsInfo = ({ firstPayment, nextPayment }) => {
  const { t } = useTranslation();
  return (
    <Flex {...styles.container}>
      <Flex {...styles.firstPaymentContainer}>
        <Typography {...styles.firstPaymentTitle}>{formatSubscriptionPrice(firstPayment)}</Typography>
        <Typography {...styles.paymentSubtitle}>{t('Subscriptions.FirstPayment')}</Typography>
      </Flex>
      <Flex {...styles.nextPaymentContainer}>
        <Typography
          {...styles.nextPaymentTitle}
        >
          {formatSubscriptionPrice(nextPayment)}
        </Typography>
        <Typography {...styles.paymentSubtitle}>{t('Subscriptions.NextPayment')}</Typography>
      </Flex>
    </Flex>
  );
};

PaymentsInfo.propTypes = {
  firstPayment: number.isRequired,
  nextPayment: number.isRequired,
};

export default PaymentsInfo;
