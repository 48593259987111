import { TRACKING_PRODUCT_TYPES } from '../../../../constants/common';
import { clearGtmDataLayer, sha256 } from '../../../../utils/helpers';

export const trackGTMBeginCheckout = async ({ sub, promotionProps = {}, authorization_info }) => {
  clearGtmDataLayer();
  window.dataLayer.push({
    event: 'begin_checkout',
    eventProps: {
      value: typeof promotionProps.discount === 'number'
        ? (sub.price - promotionProps.discount) / 100
        : sub.price / 100,
      currency: 'NOK',
      coupon: promotionProps.coupon,
      items: [{
        item_id: sub.id,
        item_name: sub.title,
        discount: promotionProps.discount / 100,
        item_category: TRACKING_PRODUCT_TYPES.subscription,
        promotion_id: sub.id,
        promotion_name: promotionProps.promotionName,
      }],
    },
    meta: {
      email: await sha256(authorization_info.email),
      eventProps: {
        content_category: 'fitness',
        content_ids: [sub.id],
        contents: [{
          id: sub.id,
          quantity: 1,
        }],
        num_items: 1,
        value: sub.price / 100,
        currency: 'NOK',
      },
    },
    userProps: {
      user_id: authorization_info.id,
      subscription_id: sub.id,
    },
  });
};
